import $ from 'cash-dom';
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';

import '../styles/main.scss';

$(() => {
  const toggleNav = () => {
    $('.hamburger').toggleClass('is-active');
    $('nav').toggleClass('open');
    $('body').toggleClass('noscroll');
  };

  // Main navigation show/hide and hamburger animation
  $('.hamburger, .search-icon').on('click', toggleNav);

  // "Back to top" arrow
  $('.top-arrow').on('click', () => {
    window.scrollTo(0, 0);
  });

  // Main navigation dropdowns
  $('.nav-folder > span').on('click', function() {
    if (!$(this).parent().hasClass('open')) {
      $('.nav-folder.open').toggleClass('open');
    }

    $(this).parent().toggleClass('open');
  });

  $(window).on('keyup', function(e) {
    if (e.key === "Escape") {
      if ($('nav').hasClass('open')) {
        toggleNav();
      }

      if ($('.modal-wrapper').hasClass('visible')) {
        $('.modal-wrapper').removeClass('visible');
      }
    }
  });

  // Collapsable text
  $('.collapsable').addClass('hidden');
  $('<button class="cta cta-link toggle-collapsable">Show full text</button>').insertBefore('.collapsable');
  $('.toggle-collapsable').on('click', function(e) {
    const collapsable = $(this).next();

    if (collapsable.hasClass('hidden')) {
      $(this).text('Hide full text');
    } else {
      $(this).text('Show full text');
    }

    $(this).next().toggleClass('hidden');
  });

  // Staff bios
  $('.staff-bio').on('click', function() {
    const bio = $(this).data('bio');
    $('.modal-body').html(bio);
    $('.modal-wrapper').addClass('visible');
  });

  // Modal close button
  $('.modal-close').on('click', function() {
    $('.modal-wrapper').removeClass('visible');
  });

  $('.modal-wrapper').on('click', function() {
    $('.modal-wrapper').removeClass('visible');
  });

  $('.modal').on('click', function(e) {
    e.stopPropagation();
  });

  // FAQ
  $('.faq').on('click', function(e) {
    $(this).toggleClass('open');
  });

  // Give modal
  $('.give-modal__trigger').on('click', function() {
    const bio = $(this).data('bio');
    $('.modal-body').html($('.give-modal__contents').html());
    $('.modal-wrapper').addClass('visible');

    $('#excel-link').on('click', function() {
      $('.modal-wrapper').removeClass('visible');
    });
  });

  // Tiny slider on homepage
  if ($('.tns-slider').length) {
    tns({
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayHoverPause: true,
      container: '.tns-slider',
      controls: false,
      mouseDrag: true,
      nav: true,
    });
  }
});
